<template>
  <q-page padding>
    <q-toolbar class="q-pr-none q-mb-md">
      <q-toolbar-title class="text-h5"> Editar {{ $t('campaigns') }} </q-toolbar-title>
      <q-breadcrumbs>
        <q-breadcrumbs-el label="Dashboard" to="/ " />
        <q-breadcrumbs-el label="Campanhas" to="/campaigns" />
        <q-breadcrumbs-el label="Create Campaigns" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />

    <q-toolbar class="q-pr-none q-mb-md">
      <q-space />
      <q-btn color="primary" no-caps icon="mdi-arrow-left" label="Voltar" unelevated to="/campaigns" />
      <!-- @click="onClose" -->
    </q-toolbar>

    <q-form @submit="onEditCampaign">
      <q-card bordered flat>
        <q-tabs
          v-model="tab"
          class="text-dark"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          inline-label
          mobile-arrows
        >
          <q-route-tab name="detalhamento" label="Detalhamento" :to="`/campaigns/edit/detalhamento/${id}`" />
          <q-route-tab name="faturamento" label="Faturamento" :to="`/campaigns/edit/faturamento/${id}`" />
          <q-route-tab name="comissao_full" label="Comissão Full" :to="`/campaigns/edit/comissao_full/${id}`" />
          <q-route-tab name="historico" label="Histórico" :to="`/campaigns/edit/historico/${id}`" />
          <!-- <q-route-tab
            name="comissao_full_diferenciada"
            label="Comissão Full Diferenciada"
            :to="`/campaigns/edit/comissao_full_diferenciada/${id}`"
          /> -->
          <q-route-tab name="grupo_comissao" label="Grupo de Comissão" :to="`/campaigns/edit/grupo_comissao/${id}`" />
          <q-route-tab name="comissao_canais" label="Comissão Canais" :to="`/campaigns/edit/comissao_canais/${id}`" />
          <q-route-tab
            name="agendamento_comissao"
            label="Agendamento"
            :to="`/campaigns/edit/agendamento_comissao/${id}`"
          />
          <q-route-tab
            name="aprovacao_canais"
            label="Aprovação de Canais"
            :to="`/campaigns/edit/aprovacao_canais/${id}`"
          />
          <q-route-tab name="controle" label="Controle" :to="`/campaigns/edit/controle/${id}`" />
          <q-route-tab name="deeplink" label="Deeplink" :to="`/campaigns/edit/deeplink/${id}`" />
        </q-tabs>

        <q-separator />

        <q-tab-panels v-model="tab">
          <q-tab-panel name="detalhamento">
            <!-- <div class="text-h6">Detalhamento</div> -->

            <q-card flat class="q-mt-md">
              <q-card-section class="row items-center">
                <q-card flat>
                  <q-card-section class="row q-col-gutter-md q-pa-none">
                    <div class="col-12">
                      <span class="text-weight-medium">Status</span>
                      <div class="q-gutter-sm">
                        <q-checkbox v-model="form.is_test" val="4" :label="$t('test_campaign')" color="purple" />
                      </div>
                    </div>
                    <div class="col-3">
                      <q-select
                        v-model="form.advertiser_id"
                        :options="advertisers"
                        option-label="trademark"
                        outlined
                        clear-icon="mdi-close"
                        clearable
                        option-value="id"
                        label="Advertiser*"
                        lazy-rules
                        :rules="[(val) => !!val || 'Field is required']"
                        readonly
                        disable
                      />
                    </div>

                    <div class="col-3">
                      <q-select
                        v-model="form.agency_id"
                        :options="advertisers"
                        option-label="trademark"
                        outlined
                        clear-icon="mdi-close"
                        clearable
                        option-value="id"
                        label="Agência"
                        lazy-rules
                        :rules="[(val) => !!val || 'Field is required']"
                      />
                    </div>

                    <div class="col-2">
                      <q-input v-model="form.cj_id" outlined label="CJ ID" maxlength="20" type="number" />
                    </div>

                    <div class="col-4">
                      <q-select
                        v-model="form.comercial_manager_id"
                        use-input
                        input-debounce="200"
                        @filter="filterComercialManager"
                        :options="comercial_managersFiltered"
                        req
                        option-label="name"
                        outlined
                        clear-icon="mdi-close"
                        clearable
                        option-value="user_id"
                        label="Gerente Comercial*"
                        lazy-rules
                        :rules="[(val) => !!val || 'Field is required']"
                      />
                    </div>

                    <div class="col-6">
                      <!-- <span class="text-weight-medium">Nome da Campanha*</span> -->
                      <q-input
                        outlined
                        v-model="form.name"
                        type="text"
                        label="Nome da Campanha*"
                        lazy-rules
                        :rules="[isRequired]"
                      />
                    </div>

                    <div class="col-6">
                      <!-- <span class="text-weight-medium">Nome da Campanha*</span> -->
                      <q-input
                        outlined
                        v-model="form.campaign_description"
                        autogrow
                        label="Descrição da Campanha*"
                        lazy-rules
                        :rules="[isRequired]"
                      />
                    </div>

                    <div class="col-6">
                      <q-card flat bordered class="q-mb-lg">
                        <q-card-section class="text-center">
                          <q-btn
                            icon="mdi-close"
                            v-if="logo_url || form.logo_url"
                            unelevated
                            class="q-ma-md absolute-top-right"
                            style="z-index: 1"
                            dense
                            rounded
                            color="primary"
                            @click="removeLogo()"
                          >
                          </q-btn>

                          <q-img
                            class="col-12 q-mb-md"
                            v-if="logo_url"
                            :src="logo_url"
                            style="width: 360px; height: 200px"
                            contain
                          />
                          <q-img
                            class="col-12 q-mb-md"
                            v-else-if="previewLogoUrl"
                            :src="previewLogoUrl"
                            style="width: 360px; height: 200px"
                            contain
                          />

                          <q-avatar
                            v-else
                            square
                            class="full-width q-mb-md"
                            style="height: 200px"
                            font-size="100px"
                            :color="!$q.dark.isActive ? 'grey-3' : 'dark'"
                            :text-color="!$q.dark.isActive ? 'grey-5' : 'white'"
                            icon="mdi-file-image"
                          />

                          <q-file
                            label="Logo da campanha"
                            accept=".jpg,.jpeg,.png,.gif"
                            outlined
                            @input="onChangeImage"
                            v-model="form.logo_url"
                          >
                            <template v-slot:append>
                              <q-avatar>
                                <q-icon name="mdi-pencil" color="primary" />
                              </q-avatar>
                            </template>
                          </q-file>
                        </q-card-section>
                      </q-card>
                    </div>

                    <div class="col-6">
                      <div class="row q-col-gutter-sm">
                        <div class="col-4">
                          <q-select
                            v-model="form.url.protocol"
                            :options="['https://', 'http://']"
                            label="Protocol URL*"
                            outlined
                            clearable
                            clear-icon="mdi-close"
                            lazy-rules
                            :rules="[isRequired]"
                          />
                        </div>
                        <div class="col-8">
                          <q-input
                            outlined
                            type="text"
                            label="URL*"
                            v-model="form.url.link"
                            lazy-rules
                            :rules="[isRequired]"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-6">
                      <q-select
                        v-model="form.category_id"
                        :options="categories"
                        option-label="name"
                        outlined
                        clear-icon="mdi-close"
                        clearable
                        option-value="id"
                        label="Categoria"
                        lazy-rules
                        :rules="[(val) => !!val || 'Field is required']"
                      />
                    </div>

                    <div class="col-6">
                      <div class="row q-col-gutter-sm">
                        <div class="col-6">
                          <date-input
                            :v-model="form.activation_date"
                            outlined
                            label="Data início*"
                            @update="form.activation_date = $event"
                          />
                        </div>
                        <!-- @update="(value) => (form.activation_date = value)" -->

                        <div class="col-6">
                          <date-input
                            :v-model="form.expiration_date"
                            outlined
                            label="Data fim"
                            @update="form.expiration_date = $event"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="row q-col-gutter-sm">
                        <div class="col-4">
                          <q-select
                            v-model="form.budget_currency"
                            :options="currencyOptions"
                            emit-value
                            map-options
                            label="Budget*"
                            outlined
                            clearable
                            clear-icon="mdi-close"
                            lazy-rules
                            :rules="[isRequired]"
                          />
                        </div>
                        <div class="col-8">
                          <q-input outlined type="number" label="Valor Ilimitado*" v-model="form.budget_amount" />
                        </div>
                      </div>
                    </div>
                  </q-card-section>
                </q-card>

                <q-card flat>
                  <q-separator spaced="xl" />
                  <q-card-section class="row q-col-gutter-md q-pa-none">
                    <div class="col-6">
                      <div class="row q-col-gutter-sm">
                        <div class="col-6">
                          <q-input
                            outlined
                            type="number"
                            label="Tempo de Cookie"
                            v-model="form.cookie_time"
                            lazy-rules
                            :rules="[isRequired]"
                          />
                        </div>
                        <div class="col-6">
                          <q-select
                            v-model="form.cookie_time_metric"
                            :options="[
                              { label: 'Minutos', value: 'minutos' },
                              { label: 'Horas', value: 'horas' },
                              { label: 'Dias', value: 'dias' }
                            ]"
                            label="Periodo*"
                            outlined
                            clearable
                            clear-icon="mdi-close"
                            ref="cookie_time_metric"
                            lazy-rules
                            :rules="[isRequired]"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-6 q-gutter-sm">
                      <q-card flat bordered>
                        <q-card-section>
                          <span class="text-weight-medium">Restrição*</span>
                          <div class="q-gutter-sm">
                            <q-checkbox
                              v-model="form.restriction_site_under"
                              val="1"
                              label="Site Under"
                              color="primary"
                            />
                            <q-checkbox
                              v-model="form.restriction_word_purchase"
                              val="2"
                              label="Compra de Palavras"
                              color="primary"
                            />
                            <q-checkbox
                              v-model="form.restriction_retargeting"
                              val="3"
                              label="Retargeting"
                              color="primary"
                            />
                            <q-checkbox
                              v-model="form.restriction_email_marketing"
                              val="0"
                              label="E-mail Marketing"
                              color="primary"
                            />
                          </div>
                        </q-card-section>
                      </q-card>
                    </div>

                    <div class="col-6 q-gutter-sm">
                      <q-card flat bordered>
                        <q-card-section>
                          <span class="text-weight-medium">Atribuição*</span>
                          <div class="q-gutter-sm">
                            <q-checkbox v-model="form.last_click" label="Last Click" color="primary" />
                            <q-checkbox v-model="form.assisted" label="Assistido" color="primary" />
                            <q-checkbox v-model="form.direct" label="Direto" color="primary" />
                            <q-checkbox v-model="form.organic" label="Orgânico" color="primary" />
                          </div>
                        </q-card-section>
                      </q-card>
                    </div>

                    <div class="col-6 q-gutter-sm">
                      <q-card flat bordered>
                        <q-card-section>
                          <span class="text-weight-medium">Mobile*</span>
                          <div class="q-gutter-sm">
                            <q-checkbox v-model="form.mobile_responsive" label="Responsivo" color="primary" />
                            <q-checkbox v-model="form.mobile_msite" label="MStie" color="primary" />
                          </div>
                        </q-card-section>
                      </q-card>
                    </div>

                    <q-card class="col-12" flat>
                      <q-card-section class="q-pa-none">
                        <q-separator spaced="xl" />

                        <q-toolbar class="bg-white text-dark q-px-none">
                          <q-toolbar-title class="text-body1"> Contato Comercial (cliente) </q-toolbar-title>

                          <q-btn
                            @click="
                              form.business_contact.push({
                                id: form.business_contact.length + 1,
                                name: null,
                                email: null,
                                phone: null,
                                appendParams: {}
                              })
                            "
                            color="primary"
                            icon="mdi-plus"
                            no-caps
                            unelevated
                          >
                            <q-tooltip> Adicionar novo contato</q-tooltip>
                          </q-btn>
                        </q-toolbar>
                      </q-card-section>
                    </q-card>

                    <div
                      class="col-12 row q-col-gutter-sm q-pa-none"
                      v-for="(contact, index) in form.business_contact"
                      :key="contact.id"
                    >
                      <div class="col-4">
                        <q-input
                          outlined
                          type="text"
                          label="Nome*"
                          dense
                          v-model="contact.name"
                          lazy-rules
                          :rules="[isRequired]"
                        />
                      </div>

                      <div class="col-4">
                        <q-input
                          outlined
                          type="email"
                          label="Email*"
                          dense
                          v-model="contact.email"
                          lazy-rules
                          :rules="[isRequired, isEmail]"
                        />
                      </div>

                      <div class="col-4">
                        <q-input
                          outlined
                          type="text"
                          label="Telefone*"
                          dense
                          v-model="contact.phone"
                          mask="+## ## #####-####"
                          unmasked-value
                          hint="Ex. +55 21 99999-9999"
                          lazy-rules
                          :rules="[isRequired]"
                        >
                          <template v-if="index != 0" v-slot:after>
                            <q-btn
                              @click="form.business_contact.splice(index, 1)"
                              color="negative"
                              icon="mdi-close"
                              round
                              dense
                              unelevated
                            >
                              <q-tooltip> Remover contato</q-tooltip>
                            </q-btn>
                          </template>
                        </q-input>
                      </div>
                    </div>
                  </q-card-section>
                </q-card>

                <q-card class="col-12" flat>
                  <q-card-section class="q-pa-none">
                    <q-separator spaced="xl" />

                    <div class="col-6 q-gutter-sm">
                      <q-card flat bordered>
                        <q-card-section>
                          <span class="text-weight-medium">Tipo de Integração*</span>
                          <div class="disabled-container" :class="{ 'disabled-container': isDisabled }">
                            <q-radio v-model="integration_type" val="Pixel" label="Pixel" color="primary" />
                            <q-radio v-model="integration_type" val="Postback" label="Postback" color="primary" />
                            <q-radio v-model="integration_type" val="API" label="API" color="primary" />
                            <q-radio v-model="integration_type" val="Mobile" label="Mobile" color="primary" />
                            <q-radio v-model="integration_type" val="Outro" label="Outro" color="primary" />
                            <q-input
                              v-if="isOther"
                              v-model="otherIntegrationType"
                              label="Adicionar Informações"
                              outlined
                              dense
                              :readonly="isDisabled"
                            />
                          </div>
                        </q-card-section>
                      </q-card>
                    </div>
                    <br />

                    <div class="col-12 q-gutter-sm">
                      <div class="row q-col-gutter-md">
                        <div class="col-6">
                          <q-card flat bordered>
                            <q-card-section>
                              <span class="text-weight-medium">Mastertag*</span>
                              <div class="q-gutter-sm">
                                <p class="text">Teremos implementação de script no site do Anunciante?</p>
                                <q-select
                                  :options="['Sim', 'Não', 'Não sei']"
                                  outlined
                                  dense
                                  clearable
                                  clear-icon="mdi-close"
                                  v-model="mastertag"
                                  :readonly="isDisabled"
                                />
                              </div>
                            </q-card-section>
                          </q-card>
                        </div>
                        <div class="col-6">
                          <q-card flat bordered>
                            <q-card-section>
                              <span class="text-weight-medium">Liberação de GTM*</span>
                              <div class="q-gutter-sm">
                                <p class="text">Teremos implementação de script no site do Anunciante?</p>
                                <q-select
                                  :options="['Time Afilio', 'Cliente', 'Não sei']"
                                  outlined
                                  dense
                                  clearable
                                  clear-icon="mdi-close"
                                  v-model="gtm_release"
                                  :readonly="isDisabled"
                                />
                              </div>
                            </q-card-section>
                          </q-card>
                        </div>
                      </div>
                    </div>
                    <br />

                    <div class="row q-col-gutter-md">
                      <div class="col-12">
                        <q-input
                          outlined
                          v-model="form.parameterized_url"
                          autogrow
                          label="URL Parametrizada"
                          hint="Link da campanha já com os parâmetros de identificação de origem/mídia"
                          :rules="[isValidUrl]"
                        />
                      </div>
                    </div>
                    <br />

                    <div class="col-6 q-gutter-sm">
                      <q-card flat bordered>
                        <q-card-section>
                          <span class="text-weight-medium">Método de teste*</span>
                          <div class="q-gutter-sm">
                            <p class="text">
                              Precisaremos testar a campanha durante e após a implementação. Quais são as dificuldades
                              que podemos encontrar?
                            </p>
                            <div class="row q-col-gutter-md">
                              <div class="col-4">
                                <q-select
                                  v-model="test_method_real_data_registration"
                                  :options="['Sim', 'Não', 'Não sei']"
                                  label="Cadastro com dados reais*"
                                  outlined
                                  dense
                                  clearable
                                  clear-icon="mdi-close"
                                  :readonly="isDisabled"
                                />
                              </div>

                              <div class="col-4">
                                <q-select
                                  v-model="test_method_no_invoice_purchase"
                                  :options="['Sim', 'Não', 'Não sei']"
                                  label="Compra que não tem boleto?*"
                                  outlined
                                  dense
                                  clearable
                                  clear-icon="mdi-close"
                                  :readonly="isDisabled"
                                />
                              </div>

                              <div class="col-4">
                                <q-input
                                  outlined
                                  dense
                                  v-model="test_method_other_issues"
                                  autogrow
                                  label="Outros"
                                  :readonly="isDisabled"
                                />
                              </div>
                            </div>
                          </div>
                        </q-card-section>
                      </q-card>
                    </div>
                    <br />

                    <div class="col-6 q-gutter-sm">
                      <q-card flat bordered>
                        <q-card-section>
                          <span class="text-weight-medium">Método de comprovação*</span>
                          <div class="q-gutter-sm">
                            <p class="text">
                              Como o cliente vai validar a conversão? Relatório do GA4 com ID de Conversão +
                              Origem/Mídia é o padrão, mas ele pode usar outra ferramenta.
                            </p>
                            <div class="row q-col-gutter-md">
                              <div class="col-12">
                                <q-input
                                  outlined
                                  dense
                                  v-model="proof_method"
                                  autogrow
                                  label="Adicionar informações"
                                  :readonly="isDisabled"
                                />
                              </div>
                            </div>
                          </div>
                        </q-card-section>
                      </q-card>
                    </div>

                    <q-separator spaced="xl" />

                    <q-toolbar class="bg-white text-dark q-px-none">
                      <q-toolbar-title class="text-body1">
                        Contato para Implementação (Envio do pixel)
                      </q-toolbar-title>

                      <q-btn
                        @click="
                          form.pixel_contacts.push({
                            id: form.pixel_contacts.length + 1,
                            name: null,
                            email: null,
                            phone: null,
                            appendParams: {}
                          })
                        "
                        color="primary"
                        icon="mdi-plus"
                        no-caps
                        unelevated
                      >
                        <q-tooltip> Adicionar novo contato</q-tooltip>
                      </q-btn>
                    </q-toolbar>

                    <div
                      class="row q-col-gutter-sm q-pa-none"
                      v-for="(contact, index) in form.pixel_contacts"
                      :key="contact.id"
                    >
                      <div class="col-4">
                        <q-input
                          outlined
                          type="text"
                          label="Nome*"
                          dense
                          v-model="contact.name"
                          lazy-rules
                          :rules="[isRequired]"
                        />
                      </div>

                      <div class="col-4">
                        <q-input
                          outlined
                          type="email"
                          label="Email*"
                          dense
                          v-model="contact.email"
                          lazy-rules
                          :rules="[isRequired, isEmail]"
                        />
                      </div>

                      <div class="col-4">
                        <q-input
                          outlined
                          type="text"
                          label="Telefone*"
                          dense
                          v-model="contact.phone"
                          mask="+## ## #####-####"
                          unmasked-value
                          hint="Ex. +55 21 99999-9999"
                          lazy-rules
                          :rules="[isRequired]"
                        >
                          <template v-if="index != 0" v-slot:after>
                            <q-btn
                              @click="form.pixel_contacts.splice(index, 1)"
                              color="negative"
                              icon="mdi-close"
                              round
                              dense
                              unelevated
                            >
                              <q-tooltip> Remover contato</q-tooltip>
                            </q-btn>
                          </template>
                        </q-input>
                      </div>
                    </div>

                    <q-separator spaced="xl" />

                    <div class="row q-col-gutter-md">
                      <div class="col-6">
                        <q-select
                          v-model="form.contract"
                          :options="[
                            { label: 'Sim', value: 'sim' },
                            { label: 'Não', value: 'nao' }
                          ]"
                          label="Envio do Contrato*"
                          outlined
                          clearable
                          clear-icon="mdi-close"
                          lazy-rules
                          :rules="[isRequired]"
                        />
                      </div>

                      <div class="col-6">
                        <q-select
                          v-model="form.pi"
                          :options="[
                            { label: 'Sim', value: 'sim' },
                            { label: 'Não', value: 'nao' }
                          ]"
                          label="Envio do PI*"
                          outlined
                          clearable
                          clear-icon="mdi-close"
                          lazy-rules
                          :rules="[isRequired]"
                        />
                      </div>

                      <div class="col-12">
                        <q-input
                          outlined
                          v-model="form.additional_informations"
                          autogrow
                          label="Informações adicionais da Campanha"
                        />
                      </div>
                    </div>
                  </q-card-section>
                </q-card>
              </q-card-section>
              <q-separator inset />
              <q-card-actions align="right" class="q-pa-md">
                <q-btn unelevated label="Salvar" no-caps padding="sm lg" color="primary" type="submit" />
              </q-card-actions>
            </q-card>
          </q-tab-panel>

          <q-tab-panel name="faturamento">
            <!-- <div class="text-h6">Faturamento</div>           -->
            <CampaignBilling :id="id" class="q-pt-lg" />
          </q-tab-panel>

          <q-tab-panel name="comissao_full">
            <ComssionFull :id="id" />
          </q-tab-panel>

          <q-tab-panel name="historico"> <Historic :campaign_id="id" /> </q-tab-panel>

          <!-- <q-tab-panel name="comissao_full_diferenciada">
            <CustomCommissionFull :id="id" />
          </q-tab-panel> -->
          <q-tab-panel name="grupo_comissao">
            <ComissionGroup :id="id" />
          </q-tab-panel>
          <q-tab-panel name="comissao_canais">
            <ComissionChannel :id="id" />
          </q-tab-panel>
          <q-tab-panel name="agendamento_comissao">
            <ComissionSchedule :id="id" />
          </q-tab-panel>
          <q-tab-panel name="aprovacao_canais">
            <ChannelApproval :id="id" />
          </q-tab-panel>
          <q-tab-panel name="controle">
            <!-- <div class="text-h6">Controle</div> -->
            <Control :id="id" />
          </q-tab-panel>
          <q-tab-panel name="deeplink">
            <div class="text-h6">Deeplink</div>
            <Deeplink :id="id" />
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </q-form>
  </q-page>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';
import CampaignBilling from './CampaignBilling.vue';
import ComssionFull from './ComssionFull.vue';
import ConversorPhpService from '../../services/ConversorPhpService';
// import CustomCommissionFull from "./CustomCommissionFull.vue";
import ComissionGroup from './ComissionGroup.vue';
import ComissionChannel from './ComissionChannel.vue';
import ComissionSchedule from './ComissionSchedule.vue';
import ChannelApproval from './ChannelApproval.vue';
import Control from './Control.vue';
import Deeplink from './Deeplink.vue';
import Historic from './EditCampaignTabs/HistoricTab.vue';

export default {
  components: {
    // SelectRequest,
    DateInput,
    CampaignBilling,
    ComssionFull,
    Historic,
    // CustomCommissionFull,
    ComissionGroup,
    ComissionChannel,
    ComissionSchedule,
    ChannelApproval,
    Control,
    Deeplink
  },

  name: 'EditCampaign',

  mixins: [CampaignMixin, ConversorPhpService],

  props: {
    id: {
      type: [String, Number],
      require: true
    },
    tabCurrent: {
      type: String,
      default: 'detalhamento'
    }
  },

  data() {
    return {
      tab: 'detalhamento',
      advertisers: [],
      categories: [],
      comercial_managers: [],
      comercial_managersFiltered: [],

      step: 'step1',
      isento: false,
      logo_url: null,
      previewLogoUrl: null,
      previousFormData: null,
      form: {
        is_test: false,
        advertiser_id: null,
        agency_id: null,
        cj_id: null,
        comercial_manager_id: null,
        name: null,
        campaign_description: null,
        url: {
          protocol: null,
          link: null
        },
        category_id: null,
        activation_date: null,
        expiration_date: null,
        budget_currency: null,
        budget_amount: null,
        cookie_time: null,
        cookie_time_metric: null,

        // atribuicao:
        last_click: false,
        assisted: false,
        direct: false,
        organic: false,

        // mobile:
        mobile_responsive: false,
        mobile_msite: false,

        // restricoes:
        restriction_site_under: false,
        restriction_word_purchase: false,
        restriction_retargeting: false,
        restriction_email_marketing: false,

        contract: null,
        pi: null,
        business_contact: [{ id: 1, name: null, email: null, phone: null, appendParams: {} }],
        pixel_contacts: [{ id: 1, name: null, email: null, phone: null, appendParams: {} }],
        comissions: [],
        additional_informations: null,
        parameterized_url: ''
      },
      indexComissionsArray: 0,
      //Campos informacionais
      integration_type: null,
      otherIntegrationType: null,
      mastertag: null,
      gtm_release: null,
      parameterized_url: null,
      test_method_real_data_registration: null,
      test_method_no_invoice_purchase: null,
      test_method_other_issues: null,
      proof_method: null,
      isOther: false,
      isDisabled: true
    };
  },

  methods: {
    async uploadLogoCampaign(image) {
      try {
        this.onLoading(true);

        let form = new FormData();
        form.append('image', image);
        form.append('bucket', 's3AdvertiserLogos');

        const { data, status } = await this.$http.post('/v2/upload/campaign-logo', form, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (status === 200) return data.image_upload.image_url;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    removeLogo() {
      this.logo_url = null;
      this.form.logo_url = null;
      this.previewLogoUrl = null;
    },

    onChangeImage(value) {
      this.logo_url = null;
      this.previewLogoUrl = URL.createObjectURL(value);
      this.form.logo_url = value;
    },

    async setupPage() {
      this.onLoading(true);

      await this.getAdvertisers();
      await this.getCategories();
      await this.getComercialManagers();
      await this.getCampaignById(this.id);

      this.onLoading(false);
    },

    async getCampaignById(idCampaign) {
      if (idCampaign) {
        try {
          const { data, status } = await this.getCampaignDetails(idCampaign);
          if (status === 200) {
            this.ActionSetCampaignSelect(data);
            this.setValuesCampaign();
          }
        } catch (error) {
          if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
        }
      }
    },

    async getAdvertisers() {
      try {
        const { data, status } = await this.$http.get('/redirect?app=ADVERTISER&path=/api/get/advertiser');
        if (status === 200) this.advertisers = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async getCategories() {
      try {
        const { data, status } = await this.listCampaignsCategories();
        if (status === 200) {
          data.sort((a, b) => a.name.localeCompare(b.name));
          this.categories = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async getComercialManagers() {
      try {
        let dataOrder = [];
        const { data, status } = await this.$http.get('/user/user-unblocked-by-profile/2');
        if (status === 200) {
          dataOrder = data.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;

            return 0;
          });
          this.comercial_managers = dataOrder;
          //this.comercial_managersFiltered = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    filterComercialManager(val, update) {
      update(this.defaultFilter(val, 'comercial_managersFiltered', this.comercial_managers, 'name'));
    },

    setValuesCampaign() {
      if (this.campaignSelect) {
        this.form.is_test = this.campaignSelect.is_test == 1 ? true : false;
        this.form.advertiser_id = this.advertisers.find((item) => item.id == this.campaignSelect.advertiser_id);
        this.form.agency_id = this.advertisers.find((item) => item.id == this.campaignSelect.agency_id);
        this.form.category_id = this.categories.find((item) => item.id == this.campaignSelect.category_id);
        this.form.comercial_manager_id = this.comercial_managers.find(
          (item) => item.user_id == this.campaignSelect.comercial_manager_id
        );

        this.form.cj_id = this.campaignSelect.cj_id;
        this.form.name = this.campaignSelect.name;
        this.form.campaign_description = this.campaignSelect.campaign_description;
        this.form.url.protocol = this.campaignSelect.url.split('//')[0].concat('//');
        this.form.url.link = this.campaignSelect.url.split('//')[1];
        this.logo_url = this.campaignSelect.logo_url;
        this.form.activation_date = this.campaignSelect.activation_date;
        this.form.expiration_date = this.campaignSelect.expiration_date;
        this.form.budget_currency = this.campaignSelect.budget_currency;
        this.form.budget_amount = this.campaignSelect.budget_amount;
        this.form.cookie_time = this.campaignSelect.cookie_time;
        this.form.parameterized_url = this.campaignSelect.parameterized_url;
        this.form.cookie_time_metric = this.$refs?.cookie_time_metric?.$options?.propsData.options.find(
          (item) => item.value == this.campaignSelect.cookie_time_metric
        );

        // Contacts
        this.form.business_contact = this.campaignSelect.contact.filter((item) => item.type == 'business');
        this.form.pixel_contacts = this.campaignSelect.contact.filter((item) => item.type == 'pixel');

        // Restrictions
        this.form.restriction_site_under = this.campaignSelect.restriction_site_under == 1 ? true : false;
        this.form.restriction_email_marketing = this.campaignSelect.restriction_email_marketing == 1 ? true : false;
        this.form.restriction_retargeting = this.campaignSelect.restriction_retargeting == 1 ? true : false;
        this.form.restriction_word_purchase = this.campaignSelect.restriction_word_purchase == 1 ? true : false;

        // Atribuitions
        this.form.last_click = this.campaignSelect.last_click == 1 ? true : false;
        this.form.assisted = this.campaignSelect.assisted == 1 ? true : false;
        this.form.direct = this.campaignSelect.direct == 1 ? true : false;
        this.form.organic = this.campaignSelect.organic == 1 ? true : false;

        // Mobile
        this.form.mobile_responsive = this.campaignSelect.mobile_responsive == 1 ? true : false;
        this.form.mobile_msite = this.campaignSelect.mobile_msite == 1 ? true : false;

        this.form.contract = this.campaignSelect.contract;
        this.form.pi = this.campaignSelect.pi;
        this.form.additional_informations = this.campaignSelect.additional_informations;

        const noneSelected = !['Pixel', 'Postback', 'API', 'Mobile'].includes(this.campaignSelect.integration_type);

        if (noneSelected) {
          this.$nextTick(() => {
            this.integration_type = 'Outro';
          });
          this.isOther = true;
          this.otherIntegrationType = this.campaignSelect.integration_type;
        }

        // Campos informacionais
        this.integration_type = this.campaignSelect.integration_type;
        this.mastertag = this.campaignSelect.mastertag;
        this.gtm_release = this.campaignSelect.gtm_release;
        // this.parameterized_url = this.campaignSelect.parameterized_url;
        this.test_method_real_data_registration = this.campaignSelect.test_method_real_data_registration;
        this.test_method_no_invoice_purchase = this.campaignSelect.test_method_no_invoice_purchase;
        this.test_method_other_issues = this.campaignSelect.test_method_other_issues;
        this.proof_method = this.campaignSelect.proof_method;
      }

      this.previousFormData = JSON.stringify(this.form);
    },

    async onEditCampaign() {
      // Comparação feita para verificar se houve alteração nos dados do detalhamento de campanhas
      if (this.previousFormData == JSON.stringify(this.form)) {
        this.errorNotify('É necessário realizar alguma alteração para salvar os dados da campanha');
        return;
      }

      let formData = this.form;
      let contactAggregate = formData.business_contact.concat(formData.pixel_contacts);
      let commercialManagerId = formData.comercial_manager_id.user_id;
      let cookie_time_metric = formData.cookie_time_metric.value;
      let advertiser_id = formData.advertiser_id.id;
      let agency_id = formData.agency_id ? formData.agency_id.id : null;
      formData.is_test = formData.is_test ? 1 : 0;

      delete formData.business_contact;
      delete formData.pixel_contacts;
      delete formData.comissions;
      delete formData.cookie_time_metric;

      let data = {
        ...formData,
        url: `${this.form.url.protocol}${this.form.url.link}`,
        logo_url: false,
        category_id: this.form.category_id.id,
        // comercial_manager_id: this.form.comercial_manager_id.user_id,
        comercial_manager_id: commercialManagerId,
        contact: JSON.stringify(contactAggregate),
        cookie_time_metric: cookie_time_metric,
        advertiser_id: advertiser_id,
        agency_id: agency_id,
        contract: formData.contract.label,
        pi: formData.pi.label
      };

      if (!this.logo_url && this.previewLogoUrl) data.logo_url = await this.uploadLogoCampaign(this.form.logo_url);
      if (this.logo_url && !this.previewLogoUrl) delete data.logo_url;

      this.updateCampaign(data);
    },

    async updateCampaign(campaignData) {
      try {
        const { data, status } = await this.editCampaignDetalhamento(this.id, campaignData);
        if (status === 200 || status === 201) {
          await this.getCampaignById(this.id);

          this.successNotify(data.message);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    isValidUrl(val) {
      /* eslint-disable */
      const regex =
        /((https?|ftp)\:\/\/)?([a-z0-9+!*(),;?&=\$_.-]+(\:[a-z0-9+!*(),;?&=\$_.-]+)?@)?([:\/\a-z0-9-.]*)\.([a-z]{2,5})(\:[0-9]{2,5})?(\/([a-z0-9+\$_-]\.?)+)*\/?(\?[a-z+&\$_.-][a-z0-9!;:@&%=+\/\$_.-\|-]*)?(#[a-z_.-][a-z0-9+\$_.-]*)?/gm;
      /* eslint-enable */

      // if (!regex.test(val)) {
      //   return 'Url é inválida!';
      // }
      // return true;

      return regex.test(val) || val == '' || val == null || 'URL é inválida!';
    }
  },

  created() {
    this.setupPage();
  }
};
</script>

<style>
i.q-tabs__arrow {
  padding-top: 10px;
}

.disabled-container {
  pointer-events: none;
  opacity: 1.2;
}
</style>
